import $ from 'jquery';
import 'slick-carousel';
require('jquery-mousewheel')($);

// $('html,body').bind('mousewheel', function(event) {
//   event.preventDefault();
//   var scrollTop = this.scrollTop;
//   this.scrollTop = (scrollTop + ((event.deltaY * event.deltaFactor) * -1));
//   //console.log(event.deltaY, event.deltaFactor, event.originalEvent.deltaMode, event.originalEvent.wheelDelta);
// });

$('.slider-JS').slick({
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: '<div class="_slider__arrow _slider__arrow--next"></div>',
    prevArrow: '<div class="_slider__arrow _slider__arrow--prev"></div>',
    responsive: [
        {
          breakpoint: 1265,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
          }
        },
        {
          breakpoint: 400,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
          }
        }
      ]
  });


  


  $(document).ready(function() {

    var contentScroll1 = $(".o-kontore-text-box-JS").offset().top;
    var contentScroll2 = $(".komanda-text-box-JS").offset().top;
    var contentScroll3 = $(".praktiki-text-box-JS").offset().top;
    var contentScroll4 = $(".dostizenia-text-box-JS").offset().top;
    var contentScroll5 = $(".doverie-text-box-JS").offset().top;
    var contentScroll6 = $(".contacts-text-box-JS").offset().top;
  
      
      $("nav .menu-link-1").click(function() {
        $(".menu-link-1").addClass('active').siblings('._navigation-desktop__item').removeClass('active');
          $('body,html').animate({
              scrollTop: contentScroll1
          }, 500);
          return false;   
      });
      
  
      $("nav .menu-link-2").click(function() {
        $(".menu-link-2").addClass('active').siblings('._navigation-desktop__item').removeClass('active');
          $('body,html').animate({
              scrollTop: contentScroll2
          }, 600);
          return false;   
      });
  
      $("nav .menu-link-3").click(function() {
        $(".menu-link-3").addClass('active').siblings('._navigation-desktop__item').removeClass('active');
          $('body,html').animate({
              scrollTop: contentScroll3
          }, 700);
          return false;   
      });
  
      $("nav .menu-link-4").click(function() {
        $(".menu-link-4").addClass('active').siblings('._navigation-desktop__item').removeClass('active');
          $('body,html').animate({
              scrollTop: contentScroll4
          }, 800);
          return false;   
      });
  
      $("nav .menu-link-5").click(function() {
        $(".menu-link-5").addClass('active').siblings('._navigation-desktop__item').removeClass('active');
          $('body,html').animate({
              scrollTop: contentScroll5
          }, 900);
          return false;   
      });
  
      $("nav .menu-link-6").click(function() {
        $(".menu-link-6").addClass('active').siblings('._navigation-desktop__item').removeClass('active');
          $('body,html').animate({
              scrollTop: contentScroll6
          }, 1000);
          return false;   
      });
  
    });
